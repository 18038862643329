import React from 'react'
import '../styles/footer.css'
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from 'react-icons/fa';

function Footer() {
    const navLinks = [
        {
            link : "/about",
            text : "KNOW YOUR BANK",
        },

        {
            link : "/deposit-interest",
            text : "INTEREST RATES",
        },

        {
            link : "/loans",
            text : "LOANS",
        },

        {
            link : "/savings-bank",
            text : "DEPOSITS",
        },

        {
            link : "/rtgs-neft",
            text : "FACILITIES",
        },

        {
            link : "/gallery",
            text : "GALLERY",
        },

        {
            link : "/contact-us",
            text : "CONTACT US",
        },
    ];
  return (
    <div className='footer'>
        <div className='details'>
            <p className='name'>THE TALIKOTI SAHAKARI <br/>BANK NIYAMIT</p>
            <p className='address'>Omkar Chowk, Near Bijapur <br/>Circle,
            Talikoti 586214, <br/> Karnataka, India</p>
        </div>

        <div className='resources'>
            <p>Resources</p>
            {navLinks.map(item => 
                    <Link to={item.link}>{item.text}</Link>
                )
                }
        </div>
        <div className='contact_us'>
            <p>Contact Us</p>
            <div className='links'>
               <div><FaPhoneAlt/> : +91 8792057565</div>
               <div><MdEmail/> : ceo@talikotisahakaribank.in </div>
               <div><FaGlobe/> : https://ttsbn.in </div>

            </div>
        </div>
    </div>
  )
}

export default Footer