import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiMiniBanknotes } from "react-icons/hi2";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdOutlineDoubleArrow } from "react-icons/md";
import about_img from '../assets/images/about_img.png'
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";
import { GradientCard } from '../components/GradientCard'

function My_Slider() {
  const settings = {
    dots: true,
    infinity: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };

  const facilities=[
    {
        icon:HiMiniBanknotes,
        title:'Deposits',
        desc:'Savings Bank, Current Account, Fixed Deposit and much more',
        link:'/deposits/savings-account'
    },
    {
        icon:FaMoneyBillTransfer,
        title:'RTGS/NEFT',
        desc:'RTGS system is a nationwide funds transfer system to facilitate transfer of funds in real time.',
        link:'/deposits/savings-account'
    },
    {
        icon:FaMoneyBillTransfer,
        title:'Bank Guarantee',
        desc:'Bank Guarantee and Letter Of Credit Limits sanctioned to Members of the Bank.',
        link:'/deposits/savings-account'
    },
    {
        icon:FaMoneyBillTransfer,
        title:'Membership',
        desc:'Become the member of the bank to avail easy loan facility:',
        link:'/deposits/savings-account'
    },
];

  return (
    <div className="slider-container">
      <Slider className="slider" {...settings}>
        {facilities.map(item => 
                            <GradientCard icon={item.icon} title={item.title} desc={item.desc} link={item.link}/>
                        )}
      </Slider>
    </div>
  );
}

export default My_Slider;
