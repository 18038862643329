// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactus_highlight__0IFed{
    padding: 20px;
    border: 2px var(--secondary) solid;
    background-color: var(--secondary_bg);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    height: fit-content;
    overflow: hidden;
}

.contactus_highlight__0IFed p{
    line-height: 30px;
    display: flex;
    align-items: center;
}

.contactus_highlight__0IFed svg{
    height: 30px;
    margin-inline: 10px;
}


@media screen and (max-width: 600px){
    
    .contactus_highlight__0IFed p{
        line-height: 15px;
        margin-block: 0.5em;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/contactus.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,qCAAqC;IACrC,wCAAwC;IACxC,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;;AAGA;;IAEI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".highlight{\n    padding: 20px;\n    border: 2px var(--secondary) solid;\n    background-color: var(--secondary_bg);\n    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);\n    border-radius: 10px;\n    height: fit-content;\n    overflow: hidden;\n}\n\n.highlight p{\n    line-height: 30px;\n    display: flex;\n    align-items: center;\n}\n\n.highlight svg{\n    height: 30px;\n    margin-inline: 10px;\n}\n\n\n@media screen and (max-width: 600px){\n    \n    .highlight p{\n        line-height: 15px;\n        margin-block: 0.5em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": `contactus_highlight__0IFed`
};
export default ___CSS_LOADER_EXPORT___;
