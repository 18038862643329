// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
svg{
    vertical-align: text-top;
    color: var(--secondary);
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/facilities.module.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;IACxB,uBAAuB;AAC3B","sourcesContent":["\nsvg{\n    vertical-align: text-top;\n    color: var(--secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
