import React from 'react'
import styles from '../../styles/pages/loans_deposits.module.css'
import { MdOutlineDoubleArrow } from 'react-icons/md'

function CurrentAccount() {
    let features = ["Customer can deposit or withdraw the amount as many times required. Minimum Balance to be maintained is Rs.3,000/- in the account.", 
"Any Branch Banking Facility throughout all Branches of the Bank for operations in Current Accounts.",
"SMS banking facility for Transaction Alerts.",
"Money transfer via RTGS/NEFT.",
"Collection of CTS cheques.",
"Demand drafts payable at across  India"];

let documents = ["Passport",
                 "Driving license",
                 "PAN Card",
"Voter's Identity Card",
"NAREGA Job Card",
"Aadhar Card"];

  return (
    <div className='body'>
      <div className='heading'>
      CURRENT ACCOUNT SCHEME
      </div>
      <hr/>
      <hr/>

      <div className='body bordered_box'>

        <div className={styles.subheading}>
            Eligibility 
        </div>
        <div className={styles.text}>
            <p>
            Current Accounts can be opened by Individuals/ Businessmen / Proprietorship / Partnership Firms / Public or Private companies requiring to do multiple or numerous business transactions.
            </p>
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Features & Benefits: 
        </div>
        <div className={styles.text}>
            {features.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
        KYC Applicable As per RBI guidelines
        </div>
      </div>
    </div>
  )
}

export default CurrentAccount