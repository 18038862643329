import React from 'react'
import styles from '../styles/pages/aboutuspage.module.css'
import logo from '../assets/images/logo.png'
import "react-pdf/dist/esm/Page/TextLayer.css";

function AboutUs() {
  return (
    <div className='body'>
        <img className={styles.logo_bg} src={logo}/>
        <div className={styles.about_us}>
        <div className='heading'>
            ABOUT US
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p>Established on 28/03/1960 and licensed by Reserve Bank of India to carry on banking business The Talikoti Sahakari Bank Niyamit, Talikoti is today reckoned as one of the top-notch & leading amongst the Urban Co-operative banks operating in the region of North Karnataka. In the six decades of its existence, the performance of bank has been prototypical in terms of growth in deposits, advances & overall operational preeminent.</p>
        
        <p>The bank optimizes the traditional values and combines these with its financial acumen and unique customer service. The success of the bank lies in increasingly promoting savings at the grass root levels and channelizing it to meet the needs of low and middle-income segments of the society involved in agriculture, trade and commerce as well as, providing financial support to the salaried middle class who was hitherto denied access to institutional finance.</p>
        
        
        <p>With a view to extend banking services to the lower and middle class people of Talikoti and its surrounding villages, The Talikoti Sahakari Bank is providing devised need based loan schemes, keeping in view every imaginable need of an individual borrower and business holders.</p>
        </div>
        </div>

        <div className={styles.highlight}>
        <p className='heading'>VISION/MISSION</p>
            <p className={styles.content}>
            <img/>
                <span>Vision :</span> To be a bank that offers convienient and affordable solutions to all
                <br/> <br/>
                <span>Mission:</span> To provide basic banking services to our customers
                
                </p>
                
        </div>

        <div className={styles.history}> 
        <div className="heading">
            HISTORY
        </div>
        <hr/>
        <hr/>
        <div className='body'>
            <p>
            With a view to extend banking services to the lower and middle class people of Talikoti, eminent personalities residing in areas decided to establish a bank in 1960 under the leadership of Late Sri.S. C. Sajjan as Chairman and 8 other honarable late directors.
            </p>

            <p>
            The desire of the management was to extend banking facilities to common people on co-operative principles, the bank were started with 78 members & a paid up share capital of Rs.20100/-.At its first year it is emerged with  total Deposits of Rs.74065.30 and with the help of D C C Bank the Loan  of Rs.75000/- distributed which leads to Net Profit of Rs.3598.96 in 1961.Now,because of consistent performance & services and with love and faith of people the bank presently reaches to the  10028 share holders with a paid up share capital of over Rs.494.91 Lakhs .The present total Deposit is Rs.16578.67 Lakhs and Loan is Rs.10529.22 Lakhs. And Made profit for the financial year 2022-23 of Rs 115.61 Lacs.
            </p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default AboutUs