import React from 'react';
import styles from '../../styles/pages/facilities.module.css';
import { MdOutlineDoubleArrow } from 'react-icons/md'

function Rtgs_Neft() {
  return (
    <div className='body'>
        <div>
        <div className='heading'>
            RTGS/NEFT
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p><MdOutlineDoubleArrow/> Real time gross settlement system (RTGS) system is a nationwide funds transfer system to facilitate transfer of funds from any bank to any other bank branch in real time.</p>
        
        <p><MdOutlineDoubleArrow/> The Beneficiary gets the credit on real time.</p>
                
        <p><MdOutlineDoubleArrow/> The essential information viz., Beneficiary details such as Beneficiary Name and Account Number. Name and IFSC of the Beneficiary Bank Branch is to be provided by remitting customer.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Rtgs_Neft