import React from 'react'
import styles from '../../styles/pages/gallery_page.module.css'
import SimpleCard from '../../components/SimpleCard';
import img from '../../assets/images/about_img.png'


function PhotoGallery() {
    const photos = [
        {
            text: "Oxygen Concentrator donated to PHU Talikoti at the time of CORONA",
            img: img,
            link: '',
        }
    ];
  return (
    <div>
        <div className='body'>
      <div className='heading'>
          GALLERY
      </div>
      <hr/>
      <hr/>

      <div className='body'>
          <div className={`${styles.photo_gallery} ${styles.gallery}`}>
            <SimpleCard list={photos}></SimpleCard>
          </div>
      </div>
        
    </div>
    </div>
  )
}

export default PhotoGallery