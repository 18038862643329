import React from 'react'
import styles from '../../styles/pages/loans_deposits.module.css'
import { MdOutlineDoubleArrow } from 'react-icons/md'

function SavingsAccount() {
    let features = ["Individual can open account with Minimum Rs.500/- with no ceiling for the maximum.",
                    "Individual Deposit/ Joint Deposit / Deposit in the name of Minors with guardian can be made.",
                    "Open a Savings Bank Account with us and avail Cheque Book facility.",
                    "Free Passbook Facility.",
                    "Any Branch Banking.",
                    "Demand Draft (Local and outstation). /Pay Order.",
                    "Money transfer via RTGS/NEFT.",
                    "Nomination facility."];

let documents = ["Passport",
                 "Driving license",
                 "PAN Card",
"Voter's Identity Card",
"NAREGA Job Card",
"Aadhar Card"];

  return (
    <div className='body'>
      <div className='heading'>
      SAVING ACCOUNT SCHEME
      </div>
      <hr/>
      <hr/>

      <div className='body bordered_box'>

        <div className={styles.subheading}>
            Eligibility 
        </div>
        <div className={styles.text}>
            <p>
            A Saving Account can be opened by Individuals / Minors .
            </p>
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Features & Benefits: 
        </div>
        <div className={styles.text}>
            {features.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Interest Rate
        </div>
        <div className={styles.text}>
            <p>Interest Rate is paid half yearly on Saving Account at 3% p.a. </p>
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Officially Valid Documents:
        </div>
        <div className={styles.text}>
           <p> Three latest passport size photographs and any two of the officially valid document as Identity and Address proof As Below:</p>
            {documents.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
        </div>
      </div>
    </div>
  )
}

export default SavingsAccount