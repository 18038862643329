import React from 'react';
import styles from '../../styles/pages/facilities.module.css';
import { MdOutlineDoubleArrow } from 'react-icons/md'

function Bank_Guarantee() {
  return (
    <div className='body'>
        <div>
        <div className='heading'>
            BANK GUARANTEE
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p><MdOutlineDoubleArrow/> Bank Guarantee and Letter Of Credit Limits sanctioned to Members of the Bank.</p>
        
        <p><MdOutlineDoubleArrow/> BG/LC Limits sanctioned to customers of the Bank on the security of the deposits/Immovable properties.</p>
                
        <p><MdOutlineDoubleArrow/> The Bank can extend only financial guarantees, but not performance guarantees.</p>
        <p><MdOutlineDoubleArrow/> The tenure of the Bank guarantee shall be for maximum period of 10 years.</p>
        <p><MdOutlineDoubleArrow/> The incidental expenses in connection with issue of Bank guarantee shall be borne by the applicant. Bank guarantee commission/fees as prescribed from time to time.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Bank_Guarantee