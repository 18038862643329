import React from 'react'
import styles from '../styles/pages/interest_rates_page.module.css'

function LoanInterestRates() {
    const loan_rates = [
        {
            name: "Gold Loan",
            rate:"10%",
        },
        {
            name: "Housing Loan",
            rate:"12%",
        },
        {
            name: "Housing Loan Rs.50.00 Lakh & above",
            rate:"11%",
        },
        {
            name: "Vehicle Loan",
            rate:"12%",
        },
    ];

    const secured_loan =[
        {
            name: "Rs.25000/- to Rs.1.00 Lakh",
            rate:"14%",
        },
        {
            name: "Rs.1.00 Lakh to Rs. 10.00 Lakh",
            rate:"13%",
        },
        {
            name: "Rs. 10.00 Lakh to Rs.25.00 Lakh",
            rate:"12%",
        },
        {
            name: "Rs. 25.00 Lakh and Above",
            rate:"12%",
        },
    ];

    const salary_loan =[
        {
            name: "Rs.25000/- to Rs.10.00 Lakh",
            rate:"14%",
        },
        {
            name: "Rs.10.00 Lakh to Rs. 25.00 Lakh",
            rate:"13%",
        },
        {
            name: "Rs. 25.00 Lakh and Above",
            rate:"12%",
        },
    ];

  return (
    <div className='body'>
      <div className='heading'>
          LOAN INTEREST RATES
      </div>
      <hr/>
      <hr/>

      <div className='body'>
        <p className={styles.wef}>*w.e.f. 21.10.2022</p>
        <div className={styles.table}>
          <div className={`${styles.head} ${styles.row}`}>
            <p className={styles.col1}>LOAN</p>
            <p className={styles.col2}>INTEREST RATE</p>
          </div>

          {
            loan_rates.map(item => {
              return <div className={styles.row}>
                        <p className={styles.col1}>{item.name}</p>
                        <p className={styles.col2}>{item.rate}</p>
                      </div>
            })
          }
        </div>

        <div className='break'/>
        <div className='break'/>

        <div className={styles.table}>
          <div className={`${styles.head} ${styles.row}`}>
            <p className={styles.col1}>SECURED LOAN</p>
            <p className={styles.col2}>INTEREST RATE</p>
          </div>

          {
            secured_loan.map(item => {
              return <div className={styles.row}>
                        <p className={styles.col1}>{item.name}</p>
                        <p className={styles.col2}>{item.rate}</p>
                      </div>
            })
          }
        </div>

        <div className='break'/>
        <div className='break'/>

        <div className={styles.table}>
          <div className={`${styles.head} ${styles.row}`}>
            <p className={styles.col1}>SALARY LOAN</p>
            <p className={styles.col2}>INTEREST RATE</p>
          </div>

          {
            salary_loan.map(item => {
              return <div className={styles.row}>
                        <p className={styles.col1}>{item.name}</p>
                        <p className={styles.col2}>{item.rate}</p>
                      </div>
            })
          }
        </div>
      </div>
        
    </div>
  )
}

export default LoanInterestRates