// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest_rates_page_table__VgGc3{
    background-color: var(--secondary_bg);
    margin-inline: 50px;
    padding-top: 20px;
    border-radius: 10px;
    border: 3px solid var(--secondary);
}

.interest_rates_page_wef__2NDeR{
    margin-inline: 50px ;
}

.interest_rates_page_table__VgGc3 > :nth-child(2n) {
    background-color: white;
}

.interest_rates_page_table__VgGc3 > :nth-child(2n + 3){
    background-color: rgb(222, 222, 222);
}

.interest_rates_page_row__7IA\\+C, .interest_rates_page_head__nf3jE{
    display: flex;
}

.interest_rates_page_head__nf3jE{
    border-bottom: 1.5px solid var(--secondary);
}

.interest_rates_page_head__nf3jE p{
    font-weight: 700;
}

.interest_rates_page_row__7IA\\+C p{
    color: var(--secondary);
    padding-inline: 40px;
    
}


.interest_rates_page_row__7IA\\+C .interest_rates_page_col1__P833s{
    flex: 3 1;
}

.interest_rates_page_row__7IA\\+C .interest_rates_page_col2__nBFNO{
    flex: 2 1;
}

.interest_rates_page_row__7IA\\+C .interest_rates_page_col3__SuHK2{
    flex: 1 1;
}


@media screen and (max-width: 600px){
    .interest_rates_page_row__7IA\\+C p{
        padding-inline: 10px;
    }

    .interest_rates_page_table__VgGc3{
        margin-inline: 0px;
        padding-top: 10px;
    
    }
    
    .interest_rates_page_wef__2NDeR{
        margin-inline: 0px ;
    }
    

}`, "",{"version":3,"sources":["webpack://./src/styles/pages/interest_rates_page.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,oBAAoB;;AAExB;;;AAGA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX;;;AAGA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;;IAErB;;IAEA;QACI,mBAAmB;IACvB;;;AAGJ","sourcesContent":[".table{\n    background-color: var(--secondary_bg);\n    margin-inline: 50px;\n    padding-top: 20px;\n    border-radius: 10px;\n    border: 3px solid var(--secondary);\n}\n\n.wef{\n    margin-inline: 50px ;\n}\n\n.table > :nth-child(2n) {\n    background-color: white;\n}\n\n.table > :nth-child(2n + 3){\n    background-color: rgb(222, 222, 222);\n}\n\n.row, .head{\n    display: flex;\n}\n\n.head{\n    border-bottom: 1.5px solid var(--secondary);\n}\n\n.head p{\n    font-weight: 700;\n}\n\n.row p{\n    color: var(--secondary);\n    padding-inline: 40px;\n    \n}\n\n\n.row .col1{\n    flex: 3;\n}\n\n.row .col2{\n    flex: 2;\n}\n\n.row .col3{\n    flex: 1;\n}\n\n\n@media screen and (max-width: 600px){\n    .row p{\n        padding-inline: 10px;\n    }\n\n    .table{\n        margin-inline: 0px;\n        padding-top: 10px;\n    \n    }\n    \n    .wef{\n        margin-inline: 0px ;\n    }\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `interest_rates_page_table__VgGc3`,
	"wef": `interest_rates_page_wef__2NDeR`,
	"row": `interest_rates_page_row__7IA+C`,
	"head": `interest_rates_page_head__nf3jE`,
	"col1": `interest_rates_page_col1__P833s`,
	"col2": `interest_rates_page_col2__nBFNO`,
	"col3": `interest_rates_page_col3__SuHK2`
};
export default ___CSS_LOADER_EXPORT___;
